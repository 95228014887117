<ion-content fullscreen>
  <ion-button fill="clear" color="dark" class="modal-btn" (click)="close(false)">
    <ion-icon name="close"></ion-icon>
  </ion-button>
  <ion-grid style="height: 85%">

    <ion-row class="ion-justify-content-center ion-align-items-center" >
      <h3>{{header | translate}}</h3>
      <!-- <ion-icon name="help-circle-outline" color="warning" style="font-size: 4.5em;"></ion-icon> -->
      
      <ion-col size="10" class="ion-text-center">
        <p> {{ message | translate}} </p>

        <qrcode [hidden]="!myAngularxQrCode" [qrdata]="myAngularxQrCode" [width]="180" [errorCorrectionLevel]="'M'"></qrcode>
      </ion-col>
    </ion-row>

  </ion-grid>
  <ion-row class="ion-justify-content-center">
    <ion-col>
      <ion-button class="ion-padding" expand="block" color="success" (click)="close(true)">{{'BTN.OK' |
        translate}}</ion-button>
    </ion-col>
    <ion-col>
      <ion-button class="ion-padding" expand="block" color="danger" (click)="close(false)">{{'BTN.NO' |
        translate}}</ion-button>
    </ion-col>
  </ion-row>
</ion-content>