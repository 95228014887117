import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subscription, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DateTime, TimeSpan } from 'ews-js-api-browser';
import { SettingsInterface} from '../storage-service/storage-service.service';
import { WebsocketService } from '../api/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language-service/language.service';
import { ModalService, ModalType } from '../modal-service/modal.service';


@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private currentDay = DateTime.Now.Today;
  public clock;
  public date;
  public resource: BehaviorSubject<ResourceObject> = new BehaviorSubject(undefined);
  public resourceSettings: BehaviorSubject<SettingsInterface> = new BehaviorSubject(undefined);
  public buttoneSettings: BehaviorSubject<DoorBellButton> = new BehaviorSubject(undefined);

  public avatar = new BehaviorSubject<any>('./assets/img/icon.png');

  constructor(
    private socket: WebsocketService,
    private translate: TranslateService,
    private language: LanguageService,
  ) {

    this.startClock();
  }

  startEventListener() {
    // return this.socket.eventsCallback.pipe(
    //   switchMap(eventsList => {
        
    //     return this.checkEvents(eventsList).pipe(
    //       switchMap(events => {
    //         this.events.next(events);
    //         const aMeeting = events.find(e => e.activeStatus === 2 || e.activeStatus === 1 || e.activeStatus === 0);
    //         this.activeEvent.next(aMeeting);
    //         return this.checkMembers().pipe();
    //       })
    //     );
    //   })
    // );
  }

  startResourceListener() {
    return this.socket.resourceCallback.pipe(
      switchMap((resource: ResourceObject) => {
        
        this.checkResource(resource);
        if (resource.connectorId != null) {
          this.socket.removeConnectorCallback.next(false);
        }else{
          this.socket.removeConnectorCallback.next(true);
        }
        return this.checkButtons(resource).pipe();
      })
    );
  }


  checkResource(resource: ResourceObject) {

   // this.language.setLanguage(resource.language);
  
    this.resourceSettings.next(resource.settings);

    this.resource.next(resource);
  }

 

  getMessageReaction(){
    return this.socket.getMessageReaction();
  }

  getMessageReplay(){
    return this.socket.getMessageReplay();
  }
  checkButtons(resource: ResourceObject) {
    
    
    if (resource.buttons == null || resource.buttons == undefined || resource.buttons.length == 0) {
      return of(false);
    } else {
      return of(true);
    }
  }

  startClock() {
    
    setInterval(() => {
      this.clock = new Date();
      this.date = new Date();
      //this.checkIfNextDay();
    }, 1000);
  }

  avatarBlobber(avatar) {
    
    const img = avatar;
    fetch(avatar).then(r =>
      r.blob().then(b => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(b);
        fileReader.onload = ev => {
          this.avatar.next(ev.target.result);
        };
      }));
  }
}



// ###### Interfaces ####### //

export interface ResourceObject {
  address: {
    city: string;
    countryOrRegion: string;
    postalCode: string;
    state: string;
    street: string;
  };
  addOns: Array<any>;
  connectorId: string;
  language: string;
  devices: Array<any>;
  displayName: string;
  licenseNumber: string;
  organizationId: string;
  resourceAccount: string;
  settings: SettingsInterface;
  buttons: Array<DoorBellButton>;
  _id: string;
  _t: string;
}
export interface DoorBellButton {
  _id: string;
  buttonName: string;
  team: string;
  channel: ChannelData;
  activateEmployeePIN: boolean;
  activatePhoto: boolean;
  activatePhotoPrivacyPolicy: boolean;
  employeePIN: string;
  employeePINSalt:string;
  lockURL: string;
  lockkey: string;
  subjects: DoorBellData;
  successMessage: DoorBellText;
  waitingMessage: DoorBellText;
  failedMessage: DoorBellText;
  infoMessage: DoorBellText;

}
export class DoorBellData {
  _id: string;
  title: DoorBellText;
  message: DoorBellText;
  triggerId: string;

}

export class DoorBellText {
  en: string = '';
  de: string = '';

}
export class ChannelData {
  id: string = '';
  displayname: string = '';

}
export interface iMessageResp {

  id?: string;
  etag?: string;


}


