import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, from } from 'rxjs';

import { ApiService } from '../api/api.service';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { Device } from '@awesome-cordova-plugins/device/ngx';


export interface SettingsInterface {
  buttonSettings: Array<any>
  EmployeePIN: string;
  ActivateAdditionalSubjects: boolean;
  KioskMode: boolean;
  AdditionalSubjectsDuration: number;
  NotificationDuration: number;
}

export class Settings implements SettingsInterface {
  buttonSettings;
  ActivateAdditionalSubjects = false;
  KioskMode = false;
  AdditionalSubjectsDuration = 30;
  NotificationDuration = 10;
  EmployeePIN = null;
}

export class AppSettings {
  public static defaultImage = './assets/img/icon.png';
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage, private apiService: ApiService, private device: Device) {

  }

}



