import { take } from 'rxjs/operators';
import { WebsocketService } from './services/api/websocket.service';
import { switchMap, mergeMap } from 'rxjs/operators';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Platform, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Router } from '@angular/router';
import { ApiService } from './services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language-service/language.service';
import { Storage } from '@ionic/storage';
import { LicenseService } from './services/api/license.service';
import { of, Subscription, timer } from 'rxjs';
import { ModalService, ModalType } from './services/modal-service/modal.service';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { ResourceService } from './services/resources/resource.service';
import { AuthGuard } from './guards/auth.guard';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  appVersion = '1.1.2';
  private reconnectRunning = false;
  public licenseSubscription: Subscription;
  loadingComponent;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private api: ApiService,
    private licenseService: LicenseService,
    public translate: TranslateService,
    private language: LanguageService,
    private storage: Storage,
    private socket: WebsocketService,
    private modalService: ModalService,
    private insomnia: Insomnia,
    private version: AppVersion,
    private resourceService: ResourceService,
    public authGuard: AuthGuard,
    private render: Renderer2,
    private loadingCtrl: LoadingController,
    private translationService: TranslateService

  ) {
    this.initializeApp();

  }

  ngOnInit() {
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
      this.insomnia.keepAwake().then();
      if (this.platform.is('cordova')) {
        this.version.getVersionNumber().then(version => {
          if (version !== null) {
            this.api.softwareVersion.next(version);
          } else {
            this.api.softwareVersion.next(this.appVersion);
          }
        });
      } else {
        this.api.softwareVersion.next(this.appVersion);
      }
      this.language.setInitLang();


      /*Start resource listening changes*/
      this.resourceService.startResourceListener().subscribe((res: any) => {
        if (res) {
          if (this.licenseService.license.getValue() != null) {
            this.licenseService.checkingLicense(this.licenseService.license.getValue()).toPromise();
          }
        }
      });

      this.licenseSubscription = this.licenseService.licenseListener().subscribe();

      /*Remove Connector Callback*/
      this.socket.removeConnectorCallback.subscribe(r => {
        if (r) {
          this.socket.connectionCloseWithReconnect();
        }
      });

      /*Reconnect Socket if undefined status of connection */
      this.socket.socketStatus.pipe(
        mergeMap(() => {

          if (!this.reconnectRunning) {
            this.reconnectRunning = true;
            let time = Math.floor(Math.random() * (60 - 5 + 1) + 5);
            time = (time * 1000);

            return timer(time).pipe(switchMap(() => {
              this.reconnectRunning = false;
              console.log("Socket Connection");
              
              this.api.readDeviceLicense().pipe(take(1)).subscribe(li => {
                this.licenseService.checkingLicense(li).toPromise()
              });

              return this.api.createSocketConnection();
            }));
          }
          return of(true);
        })
      ).subscribe();

      /*Wipe device Callback */
      this.removeDeviceListener();

      this.api.getDeviceInfo().subscribe();
    });

    this.api.reConnectingStatus$.subscribe(reConnecting=>{
      console.log(reConnecting);
      if(reConnecting){
        this.showloading();
      }
      else{
        this.dismissloading();
      }
    })

  }

  async showloading(){
    this.loadingComponent =await this.loadingCtrl.create({
      message: this.translationService.instant("RECONNECTING"),
    });
    this.loadingComponent.present();
  }
  async dismissloading() {
    if(this.loadingComponent){
      this.loadingComponent.dismiss();
    }
  }

  removeDeviceListener() {
    this.socket.removeCallback.subscribe(() => {
      this.removeDevice();
    });
  }

  async removeDevice() {
    this.licenseSubscription.unsubscribe();
    this.socket.connectionClose();
    this.resourceService.resource.next(undefined);
    this.modalService.isModalOpen().then(v => v ? this.modalService.dismissModal().then().catch(_ => { }) : null);
    this.api.authenticationState.next(false);

    let serial;
    this.api.getSerial().then(s => {
      serial = s;
    });

    await this.api.clearStorage().then(() => {
      this.api.saveSerial(serial);
      this.api.deviceId.next(undefined);
      this.router.navigateByUrl('/login');

    });

  }

}
