<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center">{{ 'MODAL.HEADER.EMPLOYEEPIN' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content justify-content-center>
  <ion-item mode="md"> 
    <ion-input clearInput="true" mode="md" inputmode="numeric" pattern="[0-9]*"   placeholder="{{'MODAL.TEXT.EMPLOYEEPININPUT' | translate }}"  [(ngModel)]="pin" type="password" ></ion-input>
  </ion-item>
  <div style="text-align: center;">
    <ion-button  (click)= "ok()" fill="solid" size="default" shape="round" color="dark">OK</ion-button>
  </div>  
</ion-content>
