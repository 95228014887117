import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ConditionalModalPage } from './conditional-modal/conditional-modal.page';
import { ErrorModalPage } from './error-modal/error-modal.page';

import { SuccessModalPage } from './success-modal/success-modal.page';
import { PrivacyPolicyModalPage } from 'src/app/modals/privacy-policy-modal/privacy-policy-modal.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoLicensesModalComponent } from './no-licenses-modal/no-licenses-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavigateModalComponent } from './navigate-modal/navigate-modal.component';
import { EmployeePINPage } from './employee-pin/employee-pin.page';
import { AdditionalSubjModalPage } from './additional-subj-modal/additional-subj-modal.page';
import { QRCodeModule } from 'angularx-qrcode';


@NgModule({
    declarations: [NavigateModalComponent, ConditionalModalPage, ErrorModalPage, SuccessModalPage, NoLicensesModalComponent, EmployeePINPage, AdditionalSubjModalPage, PrivacyPolicyModalPage],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        QRCodeModule,
        TranslateModule.forChild()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ModalModule { }
