import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from "@awesome-cordova-plugins/file/ngx";
//import { FirebaseCrashlytics } from '@awesome-cordova-plugins/firebase-crashlytics/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';

import { ModalModule } from './modals/modal.module';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import { SharedModule } from './components/shared.module';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AuthGuard } from './guards/auth.guard';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { QRCodeModule } from 'angularx-qrcode';
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEnGb, 'en-GB');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'md'
        }),
        QRCodeModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ModalModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        AuthGuard,
        StatusBar,
        SplashScreen,
        NetworkInterface,
        Insomnia,
        Camera,
        CameraPreview,
        File,
        Device,
        Network,
        HTTP,
        InAppBrowser,
        //FirebaseCrashlytics,
        AppVersion,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
