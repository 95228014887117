import { Injectable } from '@angular/core';
const structuredLog = require('structured-log');
const seqSink = require('structured-log-seq-sink');
@Injectable({
  providedIn: 'root'
})
export class LogerService {

  public log;
  constructor() { 
    this.log = structuredLog.configure()
    .writeTo(seqSink({
      url: 'https://seq.hxa.io/',
      apiKey: "mJdg7vZPGexvSN7S1jwK"
    }))
    .create();    
  }
}
