import { Injectable } from '@angular/core';
import { SuccessModalPage } from 'src/app/modals/success-modal/success-modal.page';
import { ModalController } from '@ionic/angular';
import { ConditionalModalPage } from 'src/app/modals/conditional-modal/conditional-modal.page';
import { NoLicensesModalComponent } from 'src/app/modals/no-licenses-modal/no-licenses-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { NavigateModalComponent } from 'src/app/modals/navigate-modal/navigate-modal.component';
import { ErrorModalPage } from 'src/app/modals/error-modal/error-modal.page';
import { PrivacyPolicyModalPage } from 'src/app/modals/privacy-policy-modal/privacy-policy-modal.page';


export interface ModalOptions {
  component: any;
  componentProps: {
    defaultMessage: ModalMessages['defaultMessage']
    message: ModalMessages['message'];
    header: ModalMessages['header'];
  };
  backdropDismiss?: boolean;
  cssClass?: string;
}

export interface ModalMessages {
  defaultMessage?: string;
  message: string;
  header: string;
}

@Injectable({
  providedIn: 'root'
})

export class ModalService {

  constructor(private modalController: ModalController, private translate: TranslateService) {

  }

  async showModal(modelType: ModalOptions, message?: ModalMessages): Promise<any> {
    if (message !== undefined && message.message !== undefined) {
      modelType.componentProps.message = message.message;
    } else {
      const defaultMessage = this.translate.instant(modelType.componentProps.defaultMessage);
      modelType.componentProps.message = defaultMessage;
    }

    const modal = await this.modalController.create(modelType);

    this.isModalOpen().then(modal => { 
      if (modal) {
        this.dismissModal();    
      }
    });

    await modal.present();
    return modal.onDidDismiss().then(data => {
      return data;
    });
  }


  async isModalOpen() {
    return await this.modalController.getTop();
  }

  async dismissModal() {
    return await this.modalController.dismiss().catch(_ => {});
  }
}



export class ModalType {
  /**
   *  Test
   */


  public static SUCCESS: ModalOptions = {
    component: SuccessModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.SUCCESS' ,
      message: '',
      header : ''
    }
  };

  public static ERROR: ModalOptions = {
    component: ErrorModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.ERROR',
      message: '',
      header : ''

    },
    backdropDismiss: true,
  };


  public static NOLICENSE: ModalOptions = {
    component: NoLicensesModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.LICENSECHECK',
      message: '',
      header : ''

    },
    backdropDismiss: false,
  };

  public static NOBUTTON: ModalOptions = {
    component: NoLicensesModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.NOBUTTON',
      message: '',
      header : ''

    },
    backdropDismiss: false,
  };

  public static NOCONNECTOR: ModalOptions = {
    component: NoLicensesModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.NOCONNECTOR',
      message: '',
      header : ''

    },
    backdropDismiss: false,
  };


  // public static NOADDITIONALTITLES: ModalOptions = {
  //   component: ErrorModalPage,
  //   componentProps: {
  //     defaultMessage: 'MODAL.DEFAULTMESSAGE.NOTITLES',
  //     message: ''
  //   },
  //   backdropDismiss: true,
  // };

  // public static INPUT: ModalOptions = {
  //   component: InputModalPage,
  //   componentProps: {
  //     defaultMessage: 'Please Check your License',
  //     message: ''
  //   },
  //   backdropDismiss: false,
  // };

  // public static QUICKEVENT: ModalOptions = {
  //   component: QuickEventModalPage,
  //   componentProps: {
  //     defaultMessage: 'MODAL.DEFAULTMESSAGE.QUICKEVENT',
  //     message: ''
  //   },
  //   cssClass: 'quick-event-modal auto-height'
  // };


  public static CONDITIONAL: ModalOptions = {
    component: ConditionalModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.CONDITIONAL',
      message: '',
      header : ''

    },
  };

  public static PRIVACYCONDITIONAL: ModalOptions = {
    component: PrivacyPolicyModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.PRIVACYCONDITIONAL',
      message: '',
      header : 'MODAL.HEADER.PRIVACYPOLICYHEADER',

    },
  };

  public static NAVIGATE: ModalOptions = {
    component: NavigateModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.CONDITIONAL',
      message: '',
      header : ''

    },
  };

  /* public static CONDITIONAL: ModalOptions = {
     component: ConditionalModalPage
   };*/

}





