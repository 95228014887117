import { environment } from './../../../environments/environment';
import { WebsocketService } from './websocket.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, of, throwError, Observable, TimeoutError, Subject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { catchError, timeout, switchMap, map, tap, retryWhen, delay, take, flatMap } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { SettingsInterface } from '../storage-service/storage-service.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Router } from '@angular/router';
import { ResourceService } from '../resources/resource.service';
import { License } from './license.service';
import { Platform } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClient } from '@angular/common/http';
import { ConnStatus, NetworkserviceService } from '../Networkservice/networkservice.service';
import { LogerService } from '../logerService/loger.service';


@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl = "";
  private apiUrl = "";
  private tokenUrl = "";
  private deviceUrl = "";
  private userEndpoint = "";
  private deviceEndpoint = "";
  private resourceEndpoint = ";";
  private triggerEndpoint = "";
  private licenseEndpoint = "";
  private autodiscoverEndpoint = "";
  private deviceHubEndpoint = "";
  private imageEndpoint = "";
  public accessToken: string = null;
  private refreshToken: string = null;
  public softwareVersion = new BehaviorSubject<string>(undefined);
  public authenticationState = new BehaviorSubject(undefined);
  public loginClear = new Subject<boolean>();
  //public relogin = false;
  public deviceId = new BehaviorSubject(undefined);
  public serialNumber = new BehaviorSubject<string>(undefined);
  public isRefreshTokenError$ = new BehaviorSubject<boolean>(false);

  userCode: string;
  public deviceCode: BehaviorSubject<string> = new BehaviorSubject(null);
  public reConnectingStatus$ = new BehaviorSubject<boolean>(false);
  constructor(
    public http: HttpClient,
    private storage: Storage,
    private socket: WebsocketService,
    private device: Device,
    private resourceService: ResourceService,
    private platform: Platform,
    private httpNativ: HTTP,
    private router: Router,
    private logger: LogerService,
    private network: NetworkserviceService
  ) {
    this.baseUrl = environment.baseUrl;
    this.apiUrl = this.baseUrl + "/api";
    this.tokenUrl = this.baseUrl + "/connect/token";
    this.deviceUrl = this.baseUrl + "/connect/deviceauthorization";
    this.userEndpoint = this.apiUrl + "/Accounts";
    this.deviceEndpoint = this.apiUrl + "/Devices";
    this.licenseEndpoint = this.apiUrl + "/Licenses";
    this.resourceEndpoint = this.apiUrl + "/Resources";
    this.triggerEndpoint = this.apiUrl + "/Triggers";
    this.autodiscoverEndpoint = this.apiUrl + "/Autodiscover";
    this.deviceHubEndpoint = this.baseUrl + "/deviceHub";
    this.imageEndpoint = this.baseUrl + "/api/Events";
  }

  getDeviceAuthCode() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    //const body = { client_id: 'device' };
    //let _body = Object.keys(body).map(key => key + '=' + body[key]).join('&');
    const body = new HttpParams({
      fromObject: {
        client_id: "device",
      },
    });

    return this.http.post<any>(this.deviceUrl, body, { headers }).pipe(
      map((x) => {
        return x;
      })
    );
  }

  getTriggersURL(resourceId) {
    const headers = {
      Authorization: "Bearer " + this.accessToken,
      "Content-Type": "application/json",
    };
    return this.http
      .get<any>(this.triggerEndpoint + "/click/url/" + resourceId, { headers })
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  sendToKentix(lockKey, lockURL) {
    const headers = {
      Authorization: "Bearer " + lockKey,
      "Content-Type": "application/json",
    };
    return this.http.get(lockURL, { headers }).pipe(
      map((x) => {
        return x;
      })
    );
  }

  deviceAuth(deviceCode) {
    // const headers = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' };
    // const body = { client_id: 'device', device_code: deviceCode, grant_type: 'urn:ietf:params:oauth:grant-type:device_code' };
    // let _body = Object.keys(body).map(key => key + '=' + body[key]).join('&');

    const body = new HttpParams({
      fromObject: {
        client_id: "device",
        device_code: deviceCode,
        grant_type: "urn:ietf:params:oauth:grant-type:device_code",
      },
    });

    const headers = new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post<any>(this.tokenUrl, body, { headers }).pipe(
      map((r) => {
        if (r.access_token && r.refresh_token) {
          this.setAccessToken(r.access_token);
          this.setRefreshToken((this.refreshToken = r.refresh_token));
          // this.authenticationState.next(true);
          return true;
        }
      })
    );
  }

  getDeviceCode() {
    return this.getDeviceAuthCode().pipe(
      take(1),
      map((deviceAuthObject) => {
        if (deviceAuthObject) {
          this.userCode = deviceAuthObject.user_code;

          if (deviceAuthObject.device_code) {
            return deviceAuthObject;
          }
        }
      })
    );
  }

  tryGetLoginToken(deviceCode: string, interval?: number) {
    const retryInterval = interval * 1000;
    return this.deviceAuth(deviceCode).pipe(
      retryWhen((err) =>
        err.pipe(
          delay(retryInterval),
          tap((error) => {
            if (error.error.error !== "authorization_pending") {
              throw error;
            }
            if (error.error.error === "expired_token") {
              return throwError(error);
            }
          })
        )
      ),
      switchMap((res) => {
        if (res) {
          return this.consentDevice().pipe();
        }
      })
    );
  }

  consentDevice() {
    return this.bindDeviceToResource(this.userCode).pipe(
      switchMap((x) => {
        this.resourceService.resource.next(x);
        this.saveResourceId(x._id);
        return this.registerDevice(x.devices[0]._id).pipe();
      })
    );
  }

  bindDeviceToResource(userCode: string) {
    return this.getAccessToken().pipe(
      switchMap((token) => {
        if (token == null) {
          return this.refreshTokenRequest();
        }
        return of(token);
      }),
      switchMap((token) => {
        const headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        };
        return this.http
          .get<any>(this.apiUrl + "/resources/device/" + userCode, { headers })
          .pipe(
            timeout(30000),
            catchError((err): any => {
              if (err.status === 401) {
                return this.refreshTokenRequest().pipe(
                  switchMap((refreshToken) => {
                    if (refreshToken != null) {
                      return this.bindDeviceToResource(userCode);
                    }
                  }),
                  catchError(this.handleError)
                );
              } else {
                return this.handleError(err);
              }
            })
          );
      })
    );
  }

  registerDevice(deviceId: string) {
    return this.getUniqueDeviceId().pipe(
      switchMap((hwid) => {
        const data = { hardwareid: hwid };
        const headers = {
          Authorization: "Bearer " + this.accessToken,
          "Content-Type": "application/json",
        };

        return this.getDeviceInfo().pipe(
          switchMap((deviceOut: any) => {
            const deviceModel = {
              HardwareId: data.hardwareid,
              SerialNumber: deviceOut.serial,
              Platform: deviceOut.platform,
            };
            //this.storage.remove('serialNo');
            return this.http
              .put<HttpResponse<any>>(
                this.resourceEndpoint + "/device/" + deviceId + "/activate",
                deviceModel,
                { responseType: "json", headers, observe: "response" }
              )
              .pipe();
          })
        );
      }),
      timeout(30000),
      catchError(this.handleError)
    );
  }

  postTeamMessage(
    connectionId,
    message,
    channel,
    team,
    image,
    infoMessage,
    isPortrait
  ) {
    const headers: HttpHeaders = new HttpHeaders()
      .append("Content-Type", "application/json; charset=UTF-8")
      .append("Authorization", "Bearer " + this.accessToken);
    const messageModel = {
      message: message,
      image: image,
      channel: channel,
      team: team,
      infoMessage: infoMessage,
      portrait: isPortrait,
    };

    let uri = this.imageEndpoint + "/" + connectionId + "/message";

    //return this.http.post(this.apiUrl + '/Events/'+ connectionId +'/message' , messageModel, { headers }).pipe(timeout(30000), catchError(this.handleError));
    return this.http.post(uri, messageModel, { headers }).pipe(
      map((res) => {
        return true;
      }),
      timeout(30000),
      catchError(this.handleError)
    );
  }

  getResource() {
    return this.getresourceId().pipe(
      switchMap((id) => {
        return this.getAccessToken().pipe(
          switchMap((token) => {
            if (token == null) {
              return this.refreshTokenRequest();
            }
            return of(token);
          }),
          switchMap((token) => {
            const headers = {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            };
            return this.http
              .get(this.apiUrl + "/resources/" + id, { headers })
              .pipe(
                timeout(30000),
                catchError((err): any => {
                  if (err.status === 401) {
                    return this.refreshTokenRequest().pipe(
                      switchMap((refreshToken) => {
                        if (refreshToken != null) {
                          return this.getResource();
                        }
                      }),
                      catchError(this.handleError)
                    );
                  } else {
                    return this.handleError(err);
                  }
                })
              );
          })
        );
      })
    );
  }

  getresourceId() {
    if (this.resourceService.resource.getValue() === undefined) {
      return from(this.storage.get("resourceId")).pipe(
        switchMap((id: string) => {
          return of(id);
        })
      );
    }

    if (this.resourceService.resource.getValue()._id == undefined) {
      return of("");
    }
    return of(this.resourceService.resource.getValue()._id);
  }

  createSocketConnection() {
    return this.getUniqueDeviceId().pipe(
      switchMap((deviceid) => {
        return this.getAccessToken().pipe(
          switchMap((token) => {
            return this.socket
              .createConnection(this.deviceHubEndpoint, token, deviceid)
              .pipe(
                catchError((err) => {
                  if (err !== undefined && err.statusCode === 401) {
                    return this.refreshTokenRequest().pipe(
                      switchMap((refreshtoken) => {
                        this.socket.socketStatus.next(false);
                        return of(true);
                      })
                    );
                  }
                  setTimeout(() => this.socket.socketStatus.next(false), 10000);
                  return of(err);
                })
              );
          })
        );
      })
    );
  }

  getAccessToken() {
    if (this.accessToken == null) {
      return from(this.storage.get("access_token")).pipe(
        switchMap((token: string) => {
          if (token == null) {
            return this.refreshTokenRequest();
          }
          this.accessToken = token;
          return of(token);
        })
      );
    }
    return of(this.accessToken);
  }

  getRefreshToken() {
    if (this.refreshToken == null) {
      return from(this.storage.get("refresh_token")).pipe(
        switchMap((token) => {
          if (token == null) {
            return of(null);
          }
          this.refreshToken = token;
          return of(token);
        })
      );
    } else {
      return of(this.refreshToken);
    }
  }

  refreshTokenRequest() {
    return this.getRefreshToken().pipe(
      switchMap((refreshToken) => {
        if (refreshToken == null) {
          this.reConnectingStatus$.next(false);
          var url = this.router.url;
          if (url != "/login" && url != "/register") {
            this.router.navigateByUrl("/login");
          }
        }
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        // const body = `grant_type=refresh_token&refresh_token=${token}`;
        //const data = { grant_type: 'refresh_token', refresh_token: refreshToken, client_id: 'device' };

        //let _data = Object.keys(data).map(key => key + '=' + data[key]).join('&');

        const body = new HttpParams({
          fromObject: {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            client_id: "device",
          },
        });
        return this.http.post<any>(this.tokenUrl, body, { headers }).pipe(
          timeout(30000),
          switchMap((res) => {
            this.setAccessToken(res.access_token);
            this.authenticationState.next(true);
            this.isRefreshTokenError$.next(false);
            this.reConnectingStatus$.next(false);
            this.socket.stopConnectiongWithoutAuth();
            return of(res.access_token);
          }),
          catchError((err) => {
            if (err.status === 400) {
              this.setAccessToken(null);
              this.setRefreshToken(null);
              if (this.reConnectingStatus$.value == true) {
                this.reConnectingStatus$.next(false);
              }
              this.isRefreshTokenError$.next(true);
            } else {
              if (this.network.status.getValue() == ConnStatus.Offline) {
                this.reConnectingStatus$.next(false);
              } else {
                if (this.reConnectingStatus$.value == false) {
                  this.reConnectingStatus$.next(true);
                }
                this.isRefreshTokenError$.next(false);
              }
            }
            // this.setRefreshToken(null);
            // this.setAccessToken(null);
            this.authenticationState.next(false);
            var url = this.router.url;
            if (url != "/login" && url != "/register") {
              console.log("navigate to login from refresh token");
              this.router.navigateByUrl("/login");
            }
            return throwError(of({ error: { status: 0 } }));
          })
        );
      })
    );
  }

  getUniqueDeviceId() {
    if (this.deviceId.getValue() !== undefined) {
      return this.deviceId;
    } else {
      return from(this.storage.get("uuid")).pipe(
        switchMap((uuid) => {
          if (uuid == null) {
            try {
              if (this.device.uuid !== null) {
                uuid = this.device.uuid;
              } else {
                uuid = Guid.raw();
              }
            } catch (error) {
              uuid = Guid.raw();
            }

            this.saveUuid(uuid);
          }

          this.deviceId.next(uuid);
          return of(uuid);
        })
      );
    }
  }

  getDeviceInfo() {
    let device = {
      platform: this.device.platform,
      serial: null,
    };
    return from(
      this.getSerial().then((storageSerial) => {
        device.serial = storageSerial;

        if (storageSerial == null || storageSerial == "unknown") {
          if (this.device.serial !== "unknown" && this.device.serial !== null) {
            device.serial = this.device.serial;
          } else if (this.device.serial == null) {
            device.serial = this.genUniqueId();
            if (device.platform == null) {
              device.platform = "Electron";
            }
          } else {
            device.serial = this.genUniqueId();
            if (device.platform == null) {
              device.platform = "Electron";
            }
          }
        }
        if (device.platform == null) {
          device.platform = "Electron";
        }
        this.saveSerial(device.serial);
        this.serialNumber.next(device.serial);

        return device;
      })
    );
  }

  genUniqueId(): string {
    const dateStr = Date.now().toString(36); // convert num to base 36 and stringify

    const randomStr = Math.random().toString(36).substring(2, 8); // start at index 2 to skip decimal point

    return `${dateStr}-${randomStr}`;
  }

  saveUuid(uuid: string) {
    this.storage.set("uuid", uuid);
  }

  saveSerial(serial: string) {
    this.storage.set("serialNo", serial);
  }

  getSerial() {
    return this.storage.get("serialNo").then((result) => {
      return result;
    });
  }

  setAccessToken(token: string) {
    this.storage.set("access_token", token);
    this.accessToken = token;
  }

  setRefreshToken(token: string) {
    this.storage.set("refresh_token", token);
    this.refreshToken = token;
  }

  getRefreshTokenfromStorage() {
    return this.storage
      .get("refresh_token")
      .then((refreshToken: string) => refreshToken);
  }

  saveResourceId(resourceId: string) {
    this.storage.set("resourceId", resourceId);
  }

  clearStorage() {
    return this.storage.clear();
  }

  readDeviceLicense(): Observable<any> {
    console.log("readDeviceLicense");

    return this.getUniqueDeviceId().pipe(
      switchMap((hwid) => {
        return this.getAccessToken().pipe(
          switchMap((token) => {
            if (token == null) {
              return this.refreshTokenRequest();
            }
            return of(token);
          }),
          switchMap((token) => {
            this.accessToken = token;
            const headers = {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            };
            return this.http
              .get(this.licenseEndpoint + `/${hwid}` + "/Read", { headers })
              .pipe(
                timeout(30000),
                catchError((err) => {
                  console.log("read License error", err);

                  if (err.status === 401) {
                    return this.refreshTokenRequest().pipe(
                      switchMap((refreshtoken) => {
                        if (refreshtoken != null) {
                          return this.readDeviceLicense();
                        }
                      }),
                      catchError(this.handleError)
                    );
                  } else if (err.status === 404) {
                    const license = {} as License;
                    license.status = 404;
                    if (err.error != "LicenseNotFound") {
                      if (err.error.error != "LicenseNotFound") {
                        console.log("Should not go");
                        this.setAccessToken(null);
                        this.setRefreshToken(null);
                        license.status = 401;
                      }
                    }
                    return of(license);
                  } else {
                    return this.handleError(err);
                  }
                })
              );
          })
        );
      })
    );
  }

  private handleError(error) {
    if (error instanceof HttpErrorResponse) {
      if (error.status <= 0) {
        return throwError({
          status: 0,
          error: {
            error: "ConnectionError",
            error_description: "Connection Problems",
          },
        });
      }

      if (error.status === 401) {
        return throwError({
          status: error.status,
          error: {
            error: "UnauthorizedError",
            error_description: "Unauthorized",
          },
        });
      }

      if (error.error) {
        if (typeof error.error === "string") {
          return throwError({
            status: 0,
            error: {
              error: "ConnectionError",
              error_description: "Connection Problems",
            },
          });
        }
        return throwError({ status: error.status, error: error.error });
      }

      return throwError(error);
    }

    if (error instanceof TimeoutError) {
      return throwError({
        status: 0,
        error: {
          error: "TimeoutError",
          error_description: "Timeout has occured",
        },
      });
    }

    return throwError({
      status: 0,
      error: {
        error: "ConnectionError",
        error_description: "Connection Problems",
      },
    });

    /*if (error.error instanceof ErrorEvent) {
      return throwError(error.error);
    } else if (error.error !== undefined) {
      if ((error.error instanceof Object) === false) {
        try {
          error.error = JSON.parse(error.error);
        } catch {
          error.error = error.error;
        }
      }
      if (error.error.error_description !== undefined) {
        return throwError({ status: error.status, type: error.error.error, message: error.error.error_description });
      }
      return throwError({ status: error.status, message: error.error.message });
    } else if (error.status === 0) {
      return throwError({ status: error.status, message: 'No Connection' });
    } else {
      return throwError({ status: error.status, message: error.message });
    }*/
  }

  public putDevice(device) {
    return this.getUniqueDeviceId().pipe(
      switchMap((hwid) => {
        return this.getAccessToken().pipe(
          switchMap((token) => {
            if (token == null) {
              return this.refreshTokenRequest();
            }
            return of(token);
          }),
          switchMap((token) => {
            const headers = {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            };
            return this.http
              .put(this.resourceEndpoint + `/device/${hwid}`, device, {
                headers,
              })
              .pipe(
                timeout(30000),
                catchError((err): any => {
                  if (err.status === 401) {
                    return this.refreshTokenRequest().pipe(
                      switchMap((refreshToken) => {
                        if (refreshToken != null) {
                          return this.putDevice(device);
                        }
                      }),
                      catchError(this.handleError)
                    );
                  } else {
                    return this.handleError(err);
                  }
                })
              );
          })
        );
      })
    );
  }
  relogin(ClientRequestId: any) {
    console.log("ClientRequestId", ClientRequestId);
    return this.getRefreshToken().pipe(
      switchMap((refreshToken) => {
        if (refreshToken == null) {
          // Handle case where refreshToken is null
        }

        const headers = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const data = {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
          client_id: "device",
        };

        return this.http
          .post(this.tokenUrl, data.toString(), {
            headers,
            observe: "response",
          })
          .pipe(
            timeout(30000),
            switchMap((res: any) => {
              this.logger.log.info(
                "Refreshtoken request success! {@hardwareId}",
                this.storage.get("uuid")
              );
              this.setAccessToken(res.body.access_token);
              this.authenticationState.next(true);
              this.router.navigateByUrl("/dashboard");
              return of(res.body.access_token);
            }),
            catchError((err) => {
              this.logger.log.error(
                "Refreshtoken request failed! {@hardwareId} Error: {@Error}",
                this.storage.get("uuid"),
                err
              );
              this.authenticationState.next(false);
              this.isRefreshTokenError$.next(true);

              this.getDeviceAuthCode().subscribe((code: any) => {
                console.log("code", code);
                this.socket.reloginDevice(code.user_code, ClientRequestId);
                this.userCode = code.user_code;

                let test = setTimeout(() => {
                  this.tryGetReloginToken(code.device_code, 20).subscribe(
                    (res) => {
                      this.router.navigateByUrl("/dashboard");
                    }
                  );
                }, 20000);
              });

              return throwError(err); // Re-throw the error
            })
          );
      })
    );
  }
  tryGetReloginToken(deviceCode: string, interval?: number) {
    const retryInterval = interval * 1000;
    return this.deviceAuth(deviceCode).pipe(
      retryWhen((err) =>
        err.pipe(
          delay(retryInterval),
          tap((error) => {
            if (error.error.error !== "authorization_pending") {
              throw error;
            }
            if (error.error.error === "expired_token") {
              return throwError(error);
            }
          })
        )
      ),
      switchMap((res) => {
        if (res) {
          return this.consentReloginDevice().pipe();
        }
      })
    );
  }
  consentReloginDevice() {
    return this.bindReloginDeviceToResource(this.userCode).pipe(
      map((x: any) => {
        this.resourceService.resource.next(x);
        this.saveResourceId(x._id);

        //return this.registerDevice(x.devices[0]._id).pipe();
      })
    );
  }
  bindReloginDeviceToResource(userCode: string) {
    return this.getAccessToken().pipe(
      switchMap((token) => {
        if (token == null) {
          return this.refreshTokenRequest();
        }
        return of(token);
      }),
      switchMap((token) => {
        const headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        };
        let serial: string;
        this.serialNumber.subscribe((res) => (serial = res));
        return this.http
          .get(
            this.apiUrl +
              `/resources/edit/device?userCode=${userCode}&serialNumber=${serial}`,
            { headers: headers }
          )
          .pipe(
            timeout(30000),
            catchError((err): any => {
              if (err.status === 401) {
                return this.refreshTokenRequest().pipe(
                  switchMap((refreshToken) => {
                    if (refreshToken != null) {
                      return this.bindDeviceToResource(userCode);
                    }
                  }),
                  catchError(this.handleError)
                );
              } else {
                return this.handleError(err);
              }
            })
          );
      })
    );
  }
}

export class Guid {

  private constructor(guid: string) {
    if (!guid) { throw new TypeError('Invalid argument; `value` has no value.'); }

    this.value = Guid.EMPTY;

    if (guid && Guid.isGuid(guid)) {
      this.value = guid;
    }
  }

  public static validator = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$', 'i');

  public static EMPTY = '00000000-0000-0000-0000-000000000000';

  private value: string;

  public static isGuid(guid: any) {
    const value: string = guid.toString();
    return guid && (guid instanceof Guid || Guid.validator.test(value));
  }

  public static raw(): string {
    return [Guid.gen(2), Guid.gen(1), Guid.gen(1), Guid.gen(1), Guid.gen(3)].join('-');
  }

  private static gen(count: number) {
    let out = '';
    for (let i = 0; i < count; i++) {
      // tslint:disable-next-line:no-bitwise
      out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return out;
  }

}

