import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-employee-pin',
  templateUrl: './employee-pin.page.html',
  styleUrls: ['./employee-pin.page.scss'],
})
export class EmployeePINPage implements OnInit {

  language: string;
  pin: string;
  constructor(private modalController: ModalController, private translateService: TranslateService) {}

  ngOnInit() {
  }

  ok(){
    this.closeModal(this.pin);
  }
  async closeModal(reason) {

    await this.modalController.dismiss(reason);
  }

}
