
import { Injectable } from '@angular/core';
//import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions, CameraPreviewDimensions } from '@awesome-cordova-plugins/camera-preview/ngx';



const cameraPreviewOpts: CameraPreviewOptions = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  camera: 'front',
  tapPhoto: false,
  previewDrag: true,
  toBack: true,
  alpha: 1,
  storeToFile: false
}

const pictureOpts: CameraPreviewPictureOptions = {
  width: 1280,
  height: 1000,
  quality: 85
}
@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public privacyPolicyURL = "";
  constructor(private cameraPreview: CameraPreview) { }

  async captureImage() {
    let isCameraStarted = await this.startCamera().catch(
      (err) => { return false; }
    )

    if (isCameraStarted) {
      await new Promise(resolve => setTimeout(resolve, 500));
      let picture = await this.takePicture();
      await this.cameraPreview.stopCamera();
      return picture;
    }

    return null;
  }

  startCamera() {
    return this.cameraPreview.startCamera(cameraPreviewOpts).then(() => { return true; }
    );
  }

  takePicture() {
    return this.cameraPreview.takePicture(pictureOpts).then(
      (imageData) => { return imageData[0]; },
      (err) => {
        return null;
      }
    )
  }

}