import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core'; 
import { BehaviorSubject, Subscription } from 'rxjs';
import { DoorBellButton, ResourceService} from 'src/app/services/resources/resource.service';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Component({
  selector: 'app-additional-subj-modal',
  templateUrl: './additional-subj-modal.page.html',
  styleUrls: ['./additional-subj-modal.page.scss'],
})
export class AdditionalSubjModalPage implements OnInit {
  sub;
  isEmpPIN;
  EmpPIN;

  ModalLanguage: string;
  engLang: boolean;
  gerLang: boolean;
  language: string;



  constructor(private modalController: ModalController, public resourceService: ResourceService, private navParams: NavParams, private translateService: TranslateService) {
    this.language = this.translateService.currentLang;
    switch (this.language) {
      case "de":
        this.gerLang = true;
        this.engLang = false;
        this.ModalLanguage = "Deutsch";
        break;
      default:
        this.gerLang = false;
        this.engLang = true;
        this.ModalLanguage = "English";
        break;
    }
   }

  ngOnInit() {
  }

  employee(){
    this.closeModal("", 'employee','');
  }
  listChose(lan, message, id){
    this.closeModal(lan, message, id);
  }

  async closeModal(lan, reason, id) {
    const data = { message: reason, id : id}
    await this.modalController.dismiss(data, lan);
  }



}
