<div *ngIf="gerLang">
  <ion-toolbar>
    <ion-segment color="dark" [(ngModel)]="ModalLanguage" value="Deutsch">
      <ion-segment-button value="Deutsch">
        <span>Deutsch</span>
      </ion-segment-button>
      <ion-segment-button value="English">
        <span>English</span>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</div>

<div *ngIf="engLang">
  <ion-toolbar>
    <ion-segment color="dark" [(ngModel)]="ModalLanguage" value="English">
      <ion-segment-button value="English">
        <span>English</span>
      </ion-segment-button>
      <ion-segment-button value="Deutsch">
        <span>Deutsch</span>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</div>

<ion-content>
  <section [ngSwitch]="ModalLanguage">
    <div *ngSwitchCase="'Deutsch'">
      <ion-header>
        <ion-toolbar>
          <ion-title class="ion-text-center">Bitte Anliegen auswählen.</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-list style="text-align: center;" *ngFor="let item of sub; let i = index">
        <ion-item button detail lines="inset" (click)="listChose('DE', item.message.de, item._id)">
          {{item.title.de}}
        </ion-item>
      </ion-list>
      <div *ngIf="this.isEmpPIN && this.EmpPIN != ''" class="ion-margin-top" class="footer">
        <ion-item button detail (click)="employee()">
          <ion-label>Mitarbeiter/in</ion-label>
        </ion-item>
      </div>
    </div>

    <div *ngSwitchCase="'English'">
      <ion-header>
        <ion-toolbar>
          <ion-title class="ion-text-center">Please select your concern.</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-list style="text-align: center;" *ngFor="let item of sub; let i = index">
        <ion-item button detail lines="inset" (click)="listChose('EN', item.message.en, item._id)">
          {{item.title.en}}
        </ion-item>
      </ion-list>
      <div *ngIf="this.isEmpPIN && this.EmpPIN != ''" class="ion-margin-top" class="footer">
        <ion-item button detail class="ion-margin-top" (click)="employee()">
          <ion-label>Employee</ion-label>
        </ion-item>
      </div>
    </div>

  </section>


</ion-content>
