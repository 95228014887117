import { ChannelData } from './../resources/resource.service';
import { timeout, catchError, take, map } from 'rxjs/operators';
import { BehaviorSubject, of, Subject, throwError, from, lastValueFrom } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ResourceObject } from '../resources/resource.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private connection: signalR.HubConnection;
  private connectionwithoutAuth:signalR.HubConnection;
  public connectionId = new BehaviorSubject<string>(undefined);
  public licenseCallback = new Subject<boolean>();
  public messageReactionCallback = new BehaviorSubject<boolean>(undefined);
  public messageReplayCallback = new BehaviorSubject<string>(undefined);
  public resourceCallback = new Subject<ResourceObject>();
  public socketStatus = new Subject<boolean>();
  public connectionStatus = new BehaviorSubject<boolean>(undefined);
  public removeCallback = new Subject();
  public removeConnectorCallback = new Subject<boolean>();
  private forceStop = false;
  private api: ApiService;
  public HubConnectionStatus = new BehaviorSubject<string>(""); 
  constructor(private injector: Injector) {
  }
  createConnectionWithHubWithoutAuth(deviceHubEndPoint,serialNumber){
    if (this.connectionwithoutAuth != undefined){
      if (this.connectionwithoutAuth.state!="Connected"){
        const url = deviceHubEndPoint;
        this.connectionwithoutAuth = new signalR.HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
        
        this.connectionwithoutAuth.start().then(()=>{
          this.connectionwithoutAuth.invoke("saveConnectionOfDevicesCode", serialNumber)
        }).catch(err=> console.log(err));

        this.connectionwithoutAuth.on('sendDeviceType', data=> {
          console.log("send device Type", data);
          this.connectionwithoutAuth.invoke('ReceiveDeviceType', "DoorConnect", data); // Device send it's type to API
        });
      }
    }
    else{
      const url = deviceHubEndPoint;
        this.connectionwithoutAuth = new signalR.HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
        
        this.connectionwithoutAuth.start().then(()=>{
          this.connectionwithoutAuth.invoke("saveConnectionOfDevicesCode", serialNumber)
        }).catch(err=> console.log(err));

        this.connectionwithoutAuth.on('sendDeviceType', data=> {
          console.log("send device Type", data);
          this.connectionwithoutAuth.invoke('ReceiveDeviceType', "DoorConnect", data); // Device send it's type to API
        });
    }
    this.connectionwithoutAuth.onreconnected(connectionId => {
      this.connectionwithoutAuth.invoke("saveConnectionOfDevicesCode", serialNumber)
    });
    this.connectionwithoutAuth.on(
      'StartRemoteloginFromPortal',
      (data, ClientRequestId) => {
        if (data) {
          this.api = this.injector.get(ApiService);
          //this.loader$.next(true);
          this.api.relogin(ClientRequestId).pipe().subscribe();
        }
      }
    );
  }

  saveConnectionIdViaDeviceCode(deviceCode){
    console.log("saveConnection via device", deviceCode);
    
    this.connectionwithoutAuth.invoke('saveConnectionOfDevicesCode', deviceCode);
  }

  stopConnectiongWithoutAuth(){
    this.connectionwithoutAuth.stop();
  }
  
  createConnection(deviceHubEndpoint, accessToken, deviceId) {
    const url = deviceHubEndpoint;
    if(this.connection !== undefined && this.connection.state=="Disconnected"){
      this.connection=undefined
    }
    if (this.connection === undefined) {
      this.connection = new signalR.HubConnectionBuilder().withUrl(url, { accessTokenFactory: () => this.getToken() }).build();
      this.connection.on('LicenseCallback', data => { this.licenseCallback.next(true); });
      this.connection.on('ReactionCallback', data => { this.messageReactionCallback.next(data); });
      this.connection.on('ReplayCallback', data => {
        this.messageReplayCallback.next(data);
      });
      this.connection.on('ResourceCallback', data => {
        console.log("ResourceCallback",data);
        
        this.resourceCallback.next(data);

      });
      this.connection.on('RemoveCallback', data => { this.removeCallback.next(true); });
      this.connection.on('RemoveConnectorCallback', data => {
        this.removeConnectorCallback.next(true);
      });
      this.connection.onclose(() => {
        this.HubConnectionStatus.next("Closed");

        this.connection = undefined;
        if (!this.forceStop) {
          setTimeout(() => {
            this.socketStatus.next(false);
            this.connectionStatus.next(false);
            this.connectionId.next(null);
          }, 5000);
        } else {
          this.forceStop = false;
        }

      });

      return from(
        this.connection.start()
          .then(() => {
            this.HubConnectionStatus.next("Connected");
            return this.connection.invoke('HardwareAuthNew', deviceId);
          })
          .catch((err) => { throw err; })
          .then(() => {
            this.connectionStatus.next(true);
            this.connectionId.next(this.connection.connectionId);
            return true;
          })
          .catch((error) => {
            this.connection.stop().catch(() => { });
            throw (error);
          })
      ).pipe(take(1), timeout(30000), catchError(err => {
        this.connection = undefined;
        this.connectionStatus.next(false);
        this.connectionId.next(null);
        return throwError(err);
      }));
    } else {
      return of(true);
    }
  }

  postMessage(message: string, channel: ChannelData, team: string, image: string, infoMessage: string) {





    return from(this.connection.invoke('PostTeamMessage', message, channel, team, image, infoMessage)).pipe(map(x => {
      return x;
    }));
  }

  getMessageReaction() {
    this.connection.invoke('GetMessageReaction');
  }

  getMessageReplay() {
    this.connection.invoke('GetMessageReplay');
  }

  connectionClose() {
    if (this.connection !== undefined) {
      this.forceStop = true;
      this.connection.stop();
      this.connection = undefined;
    }
  }

  connectionCloseWithReconnect() {
    if (this.connection !== undefined) {
      this.connection.stop();
      this.connection = undefined;
    }
  }
  async getToken(): Promise<string> {
    this.api = this.injector.get(ApiService);
    const token: string = await lastValueFrom(this.api.refreshTokenRequest())
    return token
  }
  reloginDevice(newcode: any, ClientRequestId: any) {
    this.connectionwithoutAuth.invoke('relogin', newcode, ClientRequestId);
  }
}
