import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';


const LNG_KEY = 'SELECTED_LANGUAGE';

const PORTAL_LNG_KEY = 'SELECTED_PORTAL_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  langus;
  selected = new BehaviorSubject<any>(undefined) ;

  constructor(private translate: TranslateService, private storage: Storage) {
    this.setInitLang();
   }

  setInitLang() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    this.getselected();
  }


  async getselected() {
   await this.storage.get(LNG_KEY).then(val => {
       if (val) {
        this.selected.next(val);
        this.translate.use(val);
       } else {
       this.selected.next(this.translate.getBrowserLang());

       }
     });
  }




  getLanguage() {
    return [
      { text: 'English(UK)', value: 'en-GB', img: 'assets/flags/en.png' },
      { text: 'English(US)', value: 'en', img: 'assets/flags/en.png' },
      { text: 'Deutsch', value: 'de', img: 'assets/flags/de.png' }
    ];
  }

  setLanguage(lng) {
    
    this.translate.use(lng);
    this.selected.next(lng);
    this.storage.set(LNG_KEY, lng);
  }

  async setLanguageFromPortal(lng) {
    if(this.getPortalLanguage()){
      if(await this.getPortalLanguage() != lng) {
        this.setLanguage(lng)
      } else {
         this.getselected();
      }
    } else {
      this.getselected()
    }
    this.storage.set(PORTAL_LNG_KEY, lng);
  }
  
  async getPortalLanguage(){
    return await this.storage.get(PORTAL_LNG_KEY)
  }
}
