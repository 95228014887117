import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PhotoService } from 'src/app/services/camera/photo.service';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { ResourceService } from 'src/app/services/resources/resource.service';


@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.page.html',
  styleUrls: ['./privacy-policy-modal.page.scss'],
})
export class PrivacyPolicyModalPage implements OnInit {

  @Input() message: string;
  @Input() header: string;
  public myAngularxQrCode: string = null;
  constructor(private mdlCtrl: ModalController, private photoService: PhotoService) {
    //this.myAngularxQrCode = 'https://www.npmjs.com/package/angularx-qrcode';
    this.myAngularxQrCode  = this.photoService.privacyPolicyURL;

    
   }

  ngOnInit() {
    
    
  }

  close(ev: boolean) {

    this.mdlCtrl.dismiss(ev);
  }

}
