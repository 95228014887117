import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, first, distinctUntilKeyChanged } from 'rxjs/operators';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  public lang = new FormControl({value: ''}, { updateOn: 'change' });
  public languages = [];
  public selected;

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.languages = this.languageService.getLanguage();
    this.languageService.selected.subscribe(res => {
      if (res) {
        this.lang.setValue(res);
      }
    });
    this.onChanges();

  }

  onChanges() {
    if (this.lang.valueChanges) {
      this.lang.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
        this.languageService.setLanguage(val);
      });
    }
  }
}
